.enterprices-section {
    padding: 140px 0 0px 0;
    // background: var(--secondary-background-light);
    background: #F4FAFE;
    min-height: 560px;

    .clientbox-content {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAP8AAAAECAYAAABBXekQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPFSURBVHgBbVcLdtxACIO8HLoX7KGatlAPIKFxui8va489/CTErP/4+TvTPO18Mr2un2/z5/asnOtzc+6eZ1nv7Afv32vPX9am2vU8r+/kwvPlZ1+UbV3HZduYeKy//XynPju7vZzpexV/u7XO4xVjXu7mFnv73y4YTLnmlty1/hh7p/taS6kXbMHKbRuhM49KIr/7qp0HkxDcfDbDw51v7gO/1lKf57c11hp5uA831j4qYqijD069ivhy41vwQ/xtbsZcmLvmcsKJieHY5XXva8LYOPR+3yo673jvgNKEK3DsLlh0/oS3WOHf+L73i9k7H+VOZRUvPg+2tpxHyWZNsHXvoi9ey/dB5IVlRff59TdtAHv89zURZOEmSZBskm/wpdEMIAuZ2w7g3wLcTZksLhJn83QSiSK1bQ1iyBI+/o3+CGZoIzZpCYpPRbPoEf8RM/gbss/eji/aIl8MIULHtTqQW1/luW+MzrySHVi5zZuNR5PGbES66puoL7fl1tf9Up71+RZE4mr24UVIYEubQ9QnVEdOIkogOZrHpTHa18EHTWoi2iC7xJQqBjb4rvZZUhyNdb1IDmmqxoJ31+bwm6JVFL9LAt41amE7JZgDagzc5XlikGouFKpkTSyYp0GXc3nL4YN46gWKXfpdq7cQjG5zoe18fv0RjnWgmLim747OFvwQUtuJzrpTHUAGG5kBPXfqiZ2ZEhErACNA0ZmBEJ4CCqviLVwjcL5URbGm2G47rSASLDD8oomuqW8XKXfVFhTavBoqavq0aE66MwnlhCIVzBG0HP8n6GC+0oI2A+HE3/W7GpgikEPnTCFEdxr1k82wU6Wc5h5D1j5tJMk4BEcpohZSsA8SJMXtsnHzUoKCAyy3T6uFiLftB5acpzc3HSpsclxToJCK3acnN4o3MOr4+X5v+rhOsTt6Xqc06UeSYYRQRKwawQgMhTtNT1BsdgzRLjwHJL+lnmZXb9rnrxAQ+YABWF5TjNFbKzEnmmuBygmVUEm4Y1kn0l30gDuct+7jFIitx+h+Mr7yHWsXW3NzW7A4uWKnnJsQ25jnApRzEpD4tufEpsnJSCaTw4bLBOpPECf4b4LeNSBepp2ULiepehQUWtYKJ91HaNNRFjbTgFGjaH0K7sAJA4J19a13JitoOkmDcLCGckshwtIMj+TPjUQSg9c1TLZZHbpZk/Pi1J4weFLUY767sXQmgljijaqfRHIy6/AycnndJpb3hHJrKLbhij9nLrwHE4rrNoDN1Bt9BiVm7/wkwzB0/ESTgXPW/gHATt542gubSQAAAABJRU5ErkJggg==");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 100%;
        min-height: 160px;
        margin-bottom: 50px;

        h3 {
            // color: var(--primary-text-heading);
            color: #19191b;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
        }

        p {
            // color: var(--secondary-text-light);
            color: #686869;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
        }
    }

    .line-green {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAP8AAAAECAYAAABBXekQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAN3SURBVHgBfVddehtBCBP5cqSevUfqFdrkYakXBIhZt37JZs3wK6Sx/fz1ww3w1wcOM8tHu5/4+n6ID780R79BvLofPP4F/4azNot/5RMBQN/WNvsTbjO1yitPod6hQoq/8Ssfu4szmxTrKeNWkLHjOxqFzyuKRYWJqrsnTKarcAZgNCktTnqZezXHwH7n8/1FnDv7M32dzqRDyRGPTurE0EU87KJVj2lpIzFz8s7F+9uCTtWYM5z5ZJ4Tb/+3cwa0S3ZgcQJ6+Pj/3J2NqUbku47i+0z4acuaxWSzzI90XfpQM6u8tHsx3I8xvLyxBkYkBulnEBWxTkyB+S3sEjKAzqiqMXx+ff2ZmOkxgZTtNK3SlR2Yf1nvgXSxDoGp7HN1TfA7y8JTJiPML9vuueQJrG7v5HCCgkvSAM1CaHs7uAq4EDYjP952CW62PGHk0NaSSIaYulisba6haEtz8b0WCH0uvcsiTUDmVntlYj+EWLHGlSdZVHXAKnn3Temy6jMl9yKCmRn71uKARhUuIUvIomxkCGAzJ1MAMytxk3Bb0LGNBeiupr/GiJDtrkv14shX3Bm2CIhAetfTabXYwNqbL+10576pPbs58+z93DR5xxzyJDZMJCB7a5/f31+9mqU6W+2dgaxmb1wHFx4djtnqu4A+iUjixVqlDKKtwMcr1tWsls0qflnsjFPcsJu/rhF9BsOYai+yjfLsBF9NA72Y2I1XwqyaizDIraMOB+Exf6+8HXh3A4vVy/P/6HPHVklSQAIC4ODDIYmD1AaS2OqsAGRbaiSX6xK5Dse23io5ZmwdJJG5Qvu+4Ul6Dckm7+IxjelH/jBR276FaQ8Wvo5Fhcja+C0siDgpIRO797krF8vOBjmWgIpye98SujjyvZf7WCPvC08FnFtZxn8t/++1Qu5PZWISWOBZ1891cyA44QL0PluaKfTJicK0fVO32VCfQuDxZPt9VUVOoaI3hXHok4+y58wVisxObcR35S3HfX76jBoyuaayd8tr09wgnBywVVabjP3MrDAuNm0wfb9Bd6UcEPKiJtByp6R6eqeiypV6bbapqL4bNwdhLRLof04sZlF16RK8EimshfMMRZZtbWIbocHYnpNuNhfB8KaP9gPjb6UtR37+uDyq8L471S1k99jUeLCqPTGTtUgmCbU4btL6k3DV8hfDxZPySXIsMgAAAABJRU5ErkJggg==");
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1112px) {
    .container {
        max-width: 1000px !important;
    }
    .enterprices-section {
        padding-bottom: 0 !important;
    }

    .enterprices-section {
        padding: 80px 0 40px 0;
        min-height: 380px;

        .clientbox-content {
            min-height: 130px;
            margin-bottom: 32px;

            h3 {
                font-size: 34px;
                line-height: 24px;
            }

            p {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .enterprices-section {
        padding: 80px 0 40px 0;
        min-height: auto;
    }
    .enterprices-section .clientbox-content {
        margin-bottom: 32px;
    }

    .enterprices-section {
        padding: 80px 0 40px 0;
        min-height: 380px;

        .clientbox-content {
            min-height: 130px;
            margin-bottom: 32px;

            h3 {
                font-size: 34px;
                line-height: 24px;
            }

            p {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
    .enterprices-section .clientbox-content h3 {
        font-size: 32px;
    }

    .enterprices-section .clientbox-content p {
        font-size: 14px;
    }
}

@media (max-width: 992px) {
    .enterprices-section {
        padding: 80px 0 40px 0;

        .clientbox-content {
            min-height: 130px;
            margin-bottom: 32px;

            h3 {
                font-size: 20px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 767px) {
    .enterprices-section {
        padding: 60px 0 40px 0;

        .clientbox-content {
            min-height: 115px;
            margin-bottom: 20px;

            h3 {
                font-size: 24px;
            }

            p {
                font-size: 12px;
                margin-bottom: 0;
            }
        }
    }
}



@media (max-width: 480px) {
    .enterprices-section {
        .clientbox-content {
            min-height: 100px;
        }
    }
}